import useGetReduxState from "../../../Hooks/useGetReduxState";
import Speaker from "../../../UI/UIComponents/Speaker";
import audios from "../../../UI/UIComponents/Speaker/audios";
import langText from "../translations";
const Header = ({ component }) => {
  const lang: "hi" | "en" | "gu" | "gb" | "us" = useGetReduxState().behaviour.lang;

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "18px",
        fontWeight: "600",
        color: "#363636",
        padding: "5px",
        position: "relative",
      }}
    >
      {langText[lang].header}
      {(component === "OTP" || component === "PHONE") && (
        <div style={{ position: "absolute", top: "3px", right: "3px" }}>
          <Speaker
            sizeBg={"25px"}
            sizeIcon={"12px"}
            audioUrl={
              component === "OTP"
                ? audios[lang].ENTER_OTP
                : audios[lang].ENTER_MOBILE
            }
            disabled={false}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
