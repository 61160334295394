import useGetReduxState from "../../../Hooks/useGetReduxState";
import Speaker from "../../../UI/UIComponents/Speaker";
import audios from "../../../UI/UIComponents/Speaker/audios";

const text = {
  en: {
    heading: "Confirm your booking",
    chosen: "You had searched for trains between:",
    but: "But the train selected runs between:",
    buttons: { cancel: "Cancel", confirm: "Confirm" },
  },
  gb: {
    heading: "Confirm your booking",
    chosen: "You had searched for trains between:",
    but: "But the train selected runs between:",
    buttons: { cancel: "Cancel", confirm: "Confirm" },
  },
  us: {
    heading: "Confirm your booking",
    chosen: "You had searched for trains between:",
    but: "But the train selected runs between:",
    buttons: { cancel: "Cancel", confirm: "Confirm" },
  },
  hi: {
    heading: "अपनी बुकिंग की पुष्टि करें",
    chosen: "आपने इन स्टेशनों के बीच ट्रेनों की खोज की थी:",
    but: "लेकिन आपके द्वारा चुनी गई ट्रेन इन स्टेशनों के बीच चलती है:",
    buttons: { cancel: "रद्द करें", confirm: "बुकिंग जारी रखें" },
  },
  gu: {
    heading: "તમારા આરક્ષણની પુષ્ટિ કરો",
    chosen: "તમે વચ્ચે ટ્રેનો માટે શોધ કરી હતીઃ",
    but: "પરંતુ પસંદ કરેલી ટ્રેન આ વચ્ચે દોડે છેઃ",
    buttons: { cancel: "રદ કરો", confirm: "પુષ્ટિ કરો" },
  },
};

const Header = () => {
  const lang: "hi" | "en" | "gu" | "gb" | "us" = useGetReduxState().behaviour.lang;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "94%",
        alignItems: "center",
      }}
    >
      <span>{text[lang].heading}</span>
      <Speaker
        sizeBg={"25px"}
        sizeIcon={"12px"}
        audioUrl={audios[lang].CONFIRM_BOOKING}
        disabled={false}
      />
    </div>
  );
};

export default Header;
