import useGetReduxState from "../../../Hooks/useGetReduxState";
import Speaker from "../../../UI/UIComponents/Speaker";
import audios from "../../../UI/UIComponents/Speaker/audios";

const Header = ({ status }) => {
  const lang: "hi" | "en" | "gu" | "gb" | "us" = useGetReduxState().behaviour.lang;

  const getText = () => {
    if (lang === "en") return "Review Journey";
    if (lang === "gb") return "Review Journey";
    if (lang === "us") return "Review Journey";
    if (lang === "hi") return "यात्रा की समीक्षा";
    if (lang === "gu") return "પ્રવાસની સમીક્ષા કરો";
  };

  const getColor = (status) => {
    let check = status.toLowerCase();

    if (
      check.includes("regret") ||
      check.includes("deleted") ||
      check.includes("suspended") ||
      check.includes("departed") ||
      check.includes("not")
    )
      return "#b4b4b4";

    if (
      check.includes("available") ||
      check.includes("curr_avbl") ||
      check.includes("rac")
    )
      return "#58c75c";

    if (check.includes("wl")) return "tomato";

    return "#A5A4A6";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <p
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          columnGap: "10px",
        }}
      >
        {getText()}

        <Speaker
          sizeBg={"25px"}
          sizeIcon={"12px"}
          audioUrl={audios[lang].REVIEW_JOURNEY}
          disabled={false}
        />
      </p>

      <span
        style={{
          border: "1px solid",
          padding: "2px 5px",
          fontSize: "16px",
          fontWeight: "500",
          borderRadius: "3px",
          color: getColor(status),
        }}
      >
        {status}
      </span>
    </div>
  );
};

export default Header;
